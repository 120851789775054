.linear_bg {
  background: linear-gradient(90deg, #160099 0%, #2500ff 100%);
}

.tick_deadline_text {
  font-family: "Fira Sans", sans-serif;
  color: #545454;
  font-size: 0.875rem;
  font-weight: 600;
}

.book_spot_box {
  background-color: #bcec71;
  display: flex;
  justify-content: space-between;
  height: 100%;
  border-radius: 15px;
  padding: 10px;
}

.book_spot_text {
  font-family: "Fira Sans", sans-serif;
  color: #181818;
  font-size: 80%;
  font-weight: 700;
  width: 65%;
  line-height: normal;
  /* height: 30%; */
  text-align: left;
}

.center_flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ticket_place_text {
  color: white;
  font-family: "Fira Sans", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 61px;
}

.ticket_type_text {
  color: white;
  font-family: "Fira Sans", sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 28px;
}

.event_name_text {
  color: white;
  font-family: "Fira Sans", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
}

.ticket-table {
  width: 100%;
  max-width: 600px;
  /* border-collapse: collapse; */
  color: #c7bff4;
  text-align: left;
}

.ticket-table thead th {
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid white;
}

.ticket-table tbody td {
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.ticket-table td:not(:last-child) {
  border-right: 1px solid white;
}
