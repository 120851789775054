/* .header {
    width: 100%;
    z-index: 9999;
    position: absolute;
    background-color: #3b0f10;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
} */

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 50px;
  /* Adjust as per the actual image size */
  margin-right: 10px;
}

.logo_name {
  color: #fff;
  text-transform: uppercase;
  font-family: "Teko", sans-serif;
  font-size: 25px;
  letter-spacing: 1px;
}

.headerTabs {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.headerTabGroup {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 20px;
}

.headerTabGroup li {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.headerTabGroup li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.headerTabGroup li a:hover {
  color: #ffc353;
  /* Hover effect */
}

.register {
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  background-color: #fafafa;
  color: #3b0f10;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
}

.register a {
  color: #3b0f10;
  text-decoration: none;
  font-size: 16px;
}

.headerTabsMob {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .headerTabs {
    display: none;
  }

  .headerTabsMob {
    display: flex;
    cursor: pointer;
    margin-right: 20px;
  }

  /* .headerTabsMobContainer {
    display: flex;
    align-items: center;
  } */

  .bar1,
  .bar2,
  .bar3 {
    width: 35px;
    height: 5px;
    background-color: #ffc353;
    margin: 6px 0;
    transition: 0.4s;
  }

  .change .bar1 {
    transform: translateY(11px) rotate(-45deg);
  }

  .change .bar2 {
    opacity: 0;
  }

  .change .bar3 {
    transform: translateY(-11px) rotate(45deg);
  }
}
