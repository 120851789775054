.joinUs_pics {
  width: 220px;
  height: 220px;
}

.joinUs_p {
  font-family: "Fira Sans", sans-serif !important;
  color: #282828 !important;
  font-size: 1rem;
  font-weight: 500 !important;
}
