.about_subHead {
  font-family: "Fira Sans", sans-serif;
  text-transform: uppercase;
  color: #233ccf;
  font-size: 0.875rem;
  font-weight: 600;
}

.comma_img {
  width: 35%;
  height: 65%;
  object-fit: contain;
}

.about_mainHead {
  font-family: "Fira Sans", sans-serif;
  text-transform: uppercase;
  color: black;
  font-size: 2.25rem;
  font-weight: 900;
}

.about_detail {
  font-family: "Fira Sans", sans-serif;
  color: black;
  font-size: 1rem;
  font-weight: 500;
}
