/* src/components/EventCard.css */
.event-card {
  /* width: 290px; */
  /* margin: 20px; */
  height: max-content;
  background-color: #f9f7f4;

  border: 2px solid #f5f0e6;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;
}

.event-card:hover {
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.24), 0 17px 50px rgba(0, 0, 0, 0.19);
}

.event-img {
  width: 100%;
  height: 360px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.event-details {
  padding: 20px 20px;
}

.lochead {
  /* border: 1px solid red; */

  display: flex;
  width: 100%;
  /* border: 1px solid red; */
  /* justify-content: space-between; */
  /* align-items: center; */
}

.location {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #3b0f10;
}

/* .highlight p {
    background-color: #ffc353;
    padding: 5px 10px;
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
} */

.event-date {
  margin: 10px 0;
  font-family: "Montserrat", sans-serif;
  color: #3b0f10;
}

.register-button {
  margin: auto;
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
  justify-content: center;
  font-family: montserrat;
  font-size: small;
  font-weight: 600;
  /* padding: 16px; */
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.15);
  background-color: #fafafa;
  align-content: center;
  border-radius: 8px;
  border-bottom: 1px solid #f1e9d9;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.15);
}

.register-button a {
  padding: 10px 20px;
  /* background-color: #fff; */
  color: #3b0f10;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-family: "Montserrat", sans-serif;
}

/* .register-button a:hover {
  background-color: #ffc353;
  color: #3b0f10;
} */
