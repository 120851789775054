.mentor_image:hover {
  /* opacity: 0.5; */
  /* background-color: black; */
  /* background-color: black; */
}

.img_headText {
  color: white;
  font-weight: 900;
  font-family: "Fira Sans", sans-serif;
  text-transform: uppercase;
  font-size: 1.5rem;
}

.img_subText {
  color: white;
  font-weight: 600;
  font-family: "Fira Sans", sans-serif;
  text-transform: capitalize;
  font-size: 0.875rem;
}
