.font {
  font-family: "Fira Sans", sans-serif;
  text-transform: uppercase;
}

.partner_subHead {
  font-family: "Fira Sans", sans-serif;
  text-transform: uppercase;
  color: #233ccf;
  font-size: 0.875rem;
  font-weight: 600;
}

.partner_mainHead {
  font-family: "Fira Sans", sans-serif;
  text-transform: uppercase;
  color: black;
  font-size: 2.25rem;
  font-weight: 900;
}
