.partner_hero_head {
  font-size: 5rem;
  font-weight: 800;
  color: #323232;
}

.partner__hero_subHead {
  background-image: linear-gradient(90deg, #01d6fb 0%, #3f11fd 100%);
  -webkit-background-clip: text; /* Clips the background to the text */
  color: transparent;
  text-transform: lowercase;
  font-size: 4.688rem;
  font-weight: 800;
}

@media only screen and (max-width: 500px) {
  .partner_hero_head {
    font-size: 3rem;
  }
  .partner__hero_subHead {
    font-size: 2.688rem;
  }
}

.partner_hero_p {
  font-weight: 500;
}

.partner_hero_img {
  width: 90vw;
  height: 70vh;
  object-fit: contain;
}

.partner_form_img {
  background-image: url("/public/assets/YAssem/partner_form.svg");
  background-size: cover; /* Ensures the image covers the entire box */
  background-position: right;
  background-repeat: no-repeat; /* Prevents the image from repeating */
}

.partner_form_h {
  font-family: "Inter", sans-serif;
  color: white;
  font-size: 35px;
  font-weight: 800;
}

.partner_form_footer_h {
  font-family: "Inter", sans-serif;
  color: white;
  font-size: 18px;
  font-weight: 800;
}

.partner_form_p {
  color: white;
  font-size: 16px;
}

.partner_form_input {
  border-radius: 15px;
  padding: 16px 15px;
  border: 1px solid #746e81;
}

.grey_color {
  color: #49454f;
}

.partner_form_input::selection {
  color: #49454f;
}

.partner_form_input::placeholder {
  color: #49454f;
}

.partner_form_submit {
  background-color: #bcec71;
  color: #281eb3;
  width: 100%;
  font-weight: 600;
  border-radius: 14px;
  padding: 15px 0px;
}
