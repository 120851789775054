.eventsec {
  padding: 50px 0;
  text-align: center;
  background-color: #fff;
}

#eventhead {
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-weight: bold;
  color: #000;
}

.sec1_container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%; /* Adjust as needed */
  height: max-content; /* Adjust as needed */
}

.IntroSection {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3b0f10;
  height: 70vh;
}

@media only screen and (max-width: 1024px) {
  .event_collection {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
  }
}

.event_collection {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 32px;
  justify-content: center;
  padding: 30px 35px;
}
/* .eventbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
} */

/* .events {
  width: 300px;
  text-align: left;
} */

/* #eventimg {
  width: 100%;
  border-radius: 8px;
} */

/* .eventcontent {
  margin-top: 10px;
} */

.location {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: bold;
}

/* .highlight {
  background-color: #ffc353;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 5px;
} */

/* #eventbutton a {
  text-decoration: none;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  background-color: #ffc353;
  padding: 10px 15px;
  border-radius: 8px;
  display: inline-block;
} */
