.bg_image {
  background-image: url("/public/assets/THEILLUSTRATIONS.png");
}

.register-container {
  width: 100%;
  /* height: 90%; */
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.registerHeader {
  width: 100%;
  display: flex;
  justify-content: center;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  width: 50px;
}

.logo_name {
  margin-left: 10px;
  font-family: "Teko", sans-serif;
  font-size: 25px;
  color: #3f0d0e;
}

/* .categoryForm {
  padding: 50px;
  background-color: #fff;
  border-radius: 16px;
  text-align: center;
} */

.categoryForm {
  padding: 48px;
  background-color: #fff;
  border-radius: 16px;
  justify-content: center;
}

.formHeading {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
}

/* .catButtonBox {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
} */

.catButtonBox,
.categoryButtonBox {
  display: flex;
  gap: 30px;
}

@media only screen and (max-width: 1350px) {
  .formInputStyling {
    display: flex !important;
    flex-direction: column !important;
  }
  .categoryBox,
  .formInputStyling,
  .regStudentTab {
    align-items: center !important;
  }

  .regTab {
    align-items: center !important;
  }

  .categoryBox {
    justify-content: center;
  }
  .catButtonBox,
  .categoryBox {
    flex-direction: column;
    align-items: center;
  }
}

.categoryButtonBox {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.catButtonBox button {
  font-family: montserrat;
  height: 114px;
  width: 350px;
  background: transparent;
  border: 1px solid #79747e;
  color: #79747e !important;
  border-radius: 4px 4px 0 0;
  transition-duration: 0.8s;
}

.catButton:hover {
  background-color: #ffc353;
  border-color: #ffc353;
}

.headingStyle {
  font-weight: 700;
  margin: 0 !important;
}

.akiraFont {
  font-family: AkiraExpanded-SuperBold;
  font-size: 25px !important;
}

.headingblueColor {
  color: #170f49;
}

.formPaper {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 48px;
  border-radius: 16px;
  width: 60%; */

  /* display: none; */
  padding: 48px;
  width: 60vw;
  background-color: #fff;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.parentForm,
.studentForm {
  width: 100%;
}

.progressBox {
  width: 100%;
  margin-bottom: 30px;
  position: relative;
}

.progressBar {
  width: 100%;
  height: 12.5px;
  background-color: #eff0f6;
  border-radius: 40px;
}

.progressBar #backgroundfb {
  height: 12.5px;
  background-color: #3fcc33;
  border-radius: 40px;
}

.progressText {
  font-family: "Montserrat", sans-serif;
}

.formInputStyling {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.formInputStyle {
  width: 100%;
  height: 56px;
  border-radius: 4px;
  padding: 0px 0 0 20px;
  border: 1px solid #79747e;
  font-family: montserrat;
}

@media only screen and (max-width: 500px) {
  .regTab {
    width: 100% !important;
  }
  .formInputStyle {
    width: 100% !important;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #79747e !important;
    font-family: montserrat;
  }

  .formMultiInputStyle {
    width: 80% !important;
    height: 40px;
    border-radius: 4px;
    padding-left: 10px;
    border: 1px solid #79747e !important;
    font-family: montserrat;
  }

  .formPaper {
    width: 90vw !important;
  }
}

.formInputStyling input,
.formSelectStyle {
  width: 100%;
  height: 56px;
  padding-left: 20px;
  border-radius: 4px;
  border: 1px solid #79747e;
  font-family: "Montserrat", sans-serif;
}

textarea {
  width: 100%;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #79747e;
  font-family: "Montserrat", sans-serif;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.headingDetail {
  margin: 0;
  text-align: center;
  color: #575757;
}

.montserratFont {
  font-family: montserrat;
}

.prevButton,
.nextButton,
.submitButton {
  padding: 16px;
  border-radius: 8px;
  border: 2px solid #3b0f10;
  color: #3b0f10;
  background-color: transparent;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}

.submitButton {
  background-color: #ffc353;
  border-color: #ffc353;
}
