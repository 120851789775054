/* .about-container {
    width: 100%;
    padding: 20px;
}

.header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    display: flex;
    align-items: center;
}

.logo_name {
    font-family: 'Teko', sans-serif;
    font-size: 25px;
    color: #3b0f10;
}

.sec1_container {
    width: 100%;
}

.IntroSection {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3b0f10;
    height: 55vh;
}

.half-image-new {
    position: absolute;
    width: 40%;
    height: 55vh;
    object-fit: contain;
}

.section2 {
    display: flex;
    justify-content: space-between;
    padding: 50px;
}

.sec2Detail {
    flex: 1;
    padding: 20px;
}

.detailText p {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #333;
}

.section3 {
    display: flex;
    justify-content: space-between;
    padding: 50px;
}

.section3Image img {
    width: 100%;
    height: auto;
}

.whyItWorks {
    text-align: center;
}

.wiw_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-items: center;
    padding: 50px;
}

.wiw_sub img {
    width: 290px;
    height: 290px;
}

.wiw_sub h3 {
    font-family: 'Teko', sans-serif;
    font-size: 24px;
    color: #1b1a1c;
}

.wiw_sub p {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: #79747e;
} */

body {
  margin: 0;
}
.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logo_name {
  color: #fff;
  margin-left: 5px;
  text-transform: uppercase;
  font-family: teko;
  font-size: 25px;
}
.sec1_container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: max-content;
}
.half-image-new {
  position: absolute;
  top: 110px;
  left: 30%;
  width: 40%;
  height: 55vh;
  object-fit: contain;
}
.IntroSection {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3b0f10;
  height: 55vh;
}
.img_background {
  height: 83.5vh;
  margin-top: 40px;
  /* background-image: url(../assets/Frame100.png); */
}
#about_readmore {
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.14901960784313725);
  background-color: #fffefe;
  border: none;
  text-align: center;
  width: 121px;
  height: 52px;
  border-radius: 8px;
}
#about_readmore,
#about_readmore a {
  color: #3b0f10;
  text-decoration: None;
  font-size: medium;
  font-family: montserrat;
  font-size: small;
  font-weight: 600;
}
.blogdisplayyy {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.heading p {
  margin: 0;
  font-family: AkiraExpanded-SuperBold;
  font-size: 30px;
  font-weight: 800;
}
.detailText {
  width: 100%;
}
.detailText p {
  font-family: montserrat;
  font-size: 14px;
  font-weight: 500;
}
.section2 {
  display: flex;
  justify-content: space-between;
  padding: 100px 19% 2%;
  gap: 50px;
}
.sec2Detail {
  width: 100%;
}
.section3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8% 12%;
  gap: 15%;
}
.section2 img {
  height: 350px;
}
.section3 img {
  width: 500px;
  height: 350px;
}
#whyItWorks {
  font-family: AkiraExpanded-SuperBold;
  font-size: 24px;
  font-weight: 800;
  margin: 0;
}
.section4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}
.wiw_container {
  padding-top: 20px;
  width: 80%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.wiw_sub {
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wiw_sub p {
  font-family: montserrat;
  font-size: 16px;
  font-weight: 400;
  color: #79747e;
  margin: 0;
}
.wiw_sub h3 {
  font-family: teko;
  color: #1b1a1c;
  font-weight: 700;
  font-size: 24px;
  margin: 0;
}
.section5 {
  padding: 100px 10px;
}
@media only screen and (max-width: 1024px) {
  .wiw_container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .section3 img {
    width: 300px;
    height: 200px;
  }
  .section2 img {
    width: 250px;
    height: 200px;
  }
  .section2,
  .section3 {
    flex-direction: column;
  }
  .detailText,
  .sec2Detail {
    width: 100%;
  }
}
