.footer {
    background-color: #333;
    padding: 20px;
    text-align: center;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
}

.footer p {
    margin: 0;
    font-size: 14px;
}.footer {
  background-color: #333;
  padding: 20px;
  text-align: center;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
}

.footer p {
  margin: 0;
  font-size: 14px;
}
