.head_font {
  font-family: "Fira Sans", sans-serif;
  font-size: 2.25rem;
  font-weight: 900;
}

@media screen and (max-width: 480px) {
  .head_font {
    font-family: "Fira Sans", sans-serif;
    font-size: 1.25rem !important;
    font-weight: 900;
  }
}

.location_mainHead {
  text-transform: uppercase;
  color: black;
}

.location_subHead {
  font-family: "Fira Sans", sans-serif;
  text-transform: uppercase;
  color: #233ccf;
  font-size: 0.875rem;
  font-weight: 600;
}

.location_head {
  background-image: linear-gradient(90deg, #d1f862 0%, #ffffff 100%);
  -webkit-background-clip: text; /* Clips the background to the text */
  color: transparent;
}

.location_image {
  width: 20vw;
  height: 40%;
}

@media only screen and (max-width: 700px) {
  .location_image {
    width: 40vw !important;
    height: 30vh !important ;
  }
}

.location_date {
  font-family: "Fira Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 900;
  color: white;
}

.table_cell {
  text-align: start;
}

.table_row_head {
  font-family: "Fira Sans", sans-serif;
  font-style: italic;
  font-size: 0.875rem;
  color: #ffffff;
  text-transform: capitalize;
  padding-right: 15px;
}

.table_row_detail {
  font-family: "Fira Sans", sans-serif;
  font-style: italic;
  font-weight: 700;
  font-size: 0.875rem;
  color: #ffffff;
}

.ticket-button {
  color: #ffffff;
  /* background: linear-gradient(90deg, #2500ff 0%, #160099 100%); */
  background: #2500ff;
  border-radius: 14px;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 14px;
}

.partner_link {
  color: white;
  font-weight: 600;
}
