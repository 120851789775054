.image-background {
  background-image: url("/public/assets/YAssem/rectangle.png");
  background-size: cover; /* Ensures the image covers the entire box */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}

.image-background_globe {
  background-image: url("/public/assets/YAssem/globe.svg");
  background-size: cover; /* Ensures the image covers the entire box */
  background-position: center;
  background-repeat: no-repeat; /* Prevents the image from repeating */
  /* background-position: 0px 0px; */
}

.nav-text {
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 0.875rem;
  font-weight: 600;
}

.nav-button {
  color: #2500ff;
  background-color: #ffffff;
  border-radius: 14px;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 14px;
}

.hero_mainText {
  font-family: "Fira Sans", sans-serif;
  color: #ffffff;
  font-size: 6vw;
  font-weight: 800;
}

.hero_mainText2 {
  font-family: "Fira Sans", sans-serif;
  color: #d1f862;
  font-size: 6vw;
  font-weight: 800;
}

.hero_sub {
  font-family: "Fira Sans", sans-serif;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 600;
}

.nav_button_container {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 790px) {
  .nav_button_container {
    display: none !important;
  }

  .nav_button_container_res {
    display: block !important;
  }
}

/* .nav_button_container_res {
} */

/* :hover .nav_button_container_res {
  display: none;
  cursor: pointer;
} */

.nav_button_container_res {
  display: none;
  cursor: pointer;
}

/* @media screen and (min-width: 480px) {
  .nav_button_container_res {
    display: flex !important;
  }
} */

.bar1_y,
.bar2_y,
.bar3_y {
  width: 30px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: 0.4s;
}
