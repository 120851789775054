.single_speaker {
  border-radius: 15px 15px 0px 0px;
}

.single_speaker_topBar {
  border-radius: 15px 15px 0px 0px;
  color: #2500ff;
  font-family: "Fira Sans", sans-serif;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 900;
  padding: 8px 20px 8px 20px;
}

.speaker_name {
  font-size: 1.5rem;
  text-transform: uppercase;
  font-family: "Fira Sans", sans-serif;
  color: #282828;
  font-weight: 900;
}

.image-container {
  height: 100%;
  overflow: hidden;
  border-radius: 0px 0px 15px 15px;
  position: relative;
}

.speaker_animation {
  width: 100%;
  height: 60%;
  overflow: hidden;

  object-fit: cover;
  transition: transform 0.3s ease;
}

.speaker_animation:hover .speaker_animation {
  transform: scale(1.2); /* Zoom in on hover */
}
.speaker_animation {
  transition: transform 0.3s ease;
}

.speaker_animation:hover {
  transform: scale(1.2); /* Zooms in 20% */
}

.speaker_details {
  font-family: "Fira Sans", sans-serif;
  color: #282828;
  font-size: 0.875rem;
  font-weight: 500;
}

.medal_img {
  width: 20px;
  height: 20px;
}

.bolt_img {
  width: 20px;
  height: 20px;
}

.ach_details {
  font-size: 1rem;
  color: #2500ff;
  font-weight: 700;
}
