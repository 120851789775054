.footer_bg {
  background: linear-gradient(90deg, #160099 100%, #2500ff 0%);
  width: 100%;
  height: 300px;
  border-radius: 15px;
}

.footer_text {
  /* color: #00d6fb; */
  position: absolute;
  top: 2%;
  transform: translateY(-50%);
  font-family: "Fira Sans", sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 10vw; /* Responsive font size */
  background: linear-gradient(
    to bottom,
    #2500ff 0%,
    #2500ff 48%,
    #00d6fb 48%,
    #00d6fb 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
  text-align: center;
}

.getInTouchText {
  font-size: 0.9rem;
}
