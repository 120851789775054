.fSans {
  font-family: "Fira Sans", sans-serif;
}

::-webkit-scrollbar-thumb {
  background: #0c0422 !important;
  border-radius: 30px;
}

::-webkit-scrollbar {
  width: 5px;
}
