body {
  margin: 0;
}
::-webkit-scrollbar {
  width: 10px;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #ffc353;
  border-radius: 30px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.secondary2 {
  background-color: #f5f0e6;
}
.munSubText {
  color: #fff;
}
.munHeading {
  color: #fff;
  font-family: AkiraExpanded-SuperBold;
  font-size: 25px;
}
.bottomleft {
  position: absolute;
  bottom: 8px;
  left: 16px;
  font-size: 18px;
}
.header {
  width: 100%;
  /* z-index: 9999; */
  /* position: absolute; */
  background-color: #3b0f10;
}
.headerTabsMobExpanded {
  display: none;
  height: 0;
  transition: height 2s;
}
.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logo_name {
  color: #fff;
  margin-left: 5px;
  text-transform: uppercase;
  font-family: teko;
  font-size: 25px;
}
.register {
  display: flex;
  font-family: montserrat;
  font-size: small;
  font-weight: 600;
  justify-content: center;
  padding: 15px;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.15);
  background-color: #fafafa;
  border-radius: 8px;
  align-content: center;
}
#topbutton {
  margin-right: 15px;
}
.register a {
  color: #3b0f10;
  text-decoration: None;
  font-size: medium;
  font-family: montserrat;
  font-size: small;
  font-weight: 600;
}
.section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3b0f10;
}
.img_background {
  height: 70vh;
  margin-top: 70px;
  object-fit: cover;
}
.students_img {
  position: absolute;
  width: 350px;
  left: 50px;
  top: 120px;
}
.tag {
  left: 0;
  top: 60px;
}
.contentbox,
.tag {
  position: absolute;
}
.contentbox {
  background-color: #3b0f10;
  padding-bottom: 10px;
  border-radius: 20px;
  text-align: justify;
  width: 450px;
  right: 0;
  top: 120px;
}
.content {
  margin: 40px;
  text-align: center;
}
#line1 {
  font-family: AkiraExpanded-SuperBold;
  color: #fff;
  font-size: 13px;
}
#line1,
#line2 {
  text-transform: uppercase;
  margin-bottom: 0;
}
#line2 {
  font-family: teko;
  color: #ffc353;
  font-size: 38px;
  margin-top: 0;
  font-weight: 500;
}
#parabox {
  margin-top: 2px;
}
.para {
  font-family: montserrat;
  color: #fff;
  font-size: 11px;
  word-spacing: 2px;
  text-align: justify;
}
.content_footer,
.text {
  display: flex;
  align-items: center;
}
.text {
  color: #fff;
  margin-left: 40px;
  font-family: montserrat;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 17px;
}
#or {
  margin-left: 20px;
}
#link {
  margin-left: 8px;
}
#button2 {
  position: relative;
  left: 25px;
  bottom: 10px;
}
.vector {
  position: absolute;
  right: 4px;
  bottom: 2px;
}
.approachsec {
  margin-top: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
  flex-wrap: wrap;
}
.approachbox {
  border-radius: 15px;
  border: 3px solid #3b0f10;
  width: 70%;
  position: relative;
  display: flex;
}
.approachcontent {
  margin: 80px;
  text-align: center;
}
#head {
  text-transform: uppercase;
  color: #3b0f10;
  font-family: AkiraExpanded-SuperBold;
  font-size: 20px;
  margin-bottom: 5px;
}
#app1,
#app2 {
  font-family: montserrat;
  font-size: 12px;
  font-weight: 600;
  color: #3b0f10;
}
#app1 {
  margin-top: 22px;
  margin-bottom: 30px;
}
#app2 {
  margin-top: 30px;
}
.left {
  justify-content: flex-end;
  height: 350px;
  left: -50px;
}
.left,
.right {
  display: flex;
  flex-direction: column;
  width: 98px;
  position: absolute;
}
.right {
  justify-content: flex-start;
  right: -50px;
  top: -50px;
}
.introsec {
  width: 100%;
  margin: 70px 0 100px;
}
.introbox,
.introsec {
  display: flex;
  justify-content: center;
  align-items: center;
}
.introbox {
  width: 80%;
  gap: 100px;
}
.sound {
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.announce {
  height: 230px;
  object-fit: cover;
  margin-right: 50px;
}
.intro {
  width: 30%;
}
#introhead {
  font-family: AkiraExpanded-SuperBold;
  font-size: 20px;
}
#introhead,
#name {
  text-transform: uppercase;
  margin-bottom: 0;
}
#name {
  color: #3b0f10;
  font-family: teko;
  font-size: 40px;
  margin-top: 0;
  font-weight: 600;
}
#intropara {
  font-family: montserrat;
  font-size: 12px;
  font-weight: 600;
  text-align: justify;
}
.bio,
.icons {
  display: flex;
}
.icons {
  align-items: center;
  gap: 2px;
  width: "20";
  height: "20";
  margin: 4px;
}
.biotext {
  color: #7a161e;
  font-family: montserrat;
  font-size: small;
  font-weight: 600;
}
.profile {
  height: 300px;
  object-fit: cover;
}
.reasoncontainer {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}
.reason {
  text-align: center;
  width: 650px;
  margin-bottom: 50px;
}
#ques {
  text-transform: uppercase;
  font-family: AkiraExpanded-SuperBold;
  font-size: 20px;
}
#ans {
  font-weight: 600;
  font-family: montserrat;
  font-size: small;
}
.locimg {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 30px 200px;
  gap: 15px;
}
.locs {
  width: 300px;
  opacity: 1;
}
#eventhead {
  width: 100%;
  margin: auto;
  text-transform: uppercase;
  font-family: AkiraExpanded-SuperBold;
  font-size: 20px;
  text-align: center;
}
.eventsec {
  margin-bottom: 200px;
}
@keyframes animate-top {
  0% {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
.events {
  height: 204px;
  background-color: #f9f7f4;
  display: flex;
  border-radius: 10px;
  box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.05),
    39px 35px 15px 0 hsla(0, 0%, 78%, 0),
    25px 22px 13px 0 hsla(0, 0%, 78%, 0.01),
    14px 13px 11px 0 hsla(0, 0%, 78%, 0.05),
    6px 6px 8px 0 hsla(0, 0%, 78%, 0.09), 2px 1px 5px 0 hsla(0, 0%, 78%, 0.1);
  width: 100%;
  animation: animate-top 1s;
}
#eventimg {
  object-fit: cover;
  width: 250px;
  height: 100%;
}
.fade-in-image {
  animation: fadeIn 5s;
}
.eventcontent {
  /* border: 1px solid red; */
  width: 62%;
  padding: 30px;
}
.lochead {
  display: flex;
  align-items: center;
}
.location {
  text-align: left;
  font-family: montserrat;
  font-weight: 700;
  text-transform: uppercase;
}
.highlight {
  width: 55%;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffc353;
  border-radius: 20px;
  align-content: center;
  height: 30px;
}
.highlight p {
  font-size: 14px;
  font-family: montserrat;
  font-weight: 600;
  padding: 0 10px;
}
#date {
  margin: 0 0 5px;
}
#date,
#eventbutton {
  font-family: montserrat;
  font-size: small;
  font-weight: 600;
}
#eventbutton {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: None;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.15);
  background-color: #fafafa;
  border-radius: 8px;
  align-content: center;
  margin-bottom: 10px;
  height: 52px;
  width: 100%;
  text-align: center;
}
#eventbutton a {
  color: #3b0f10;
  text-decoration: None;
  font-size: medium;
  font-family: montserrat;
  font-size: small;
  font-weight: 600;
}
#eventbutton p {
  font-weight: 700;
  font-size: 15px;
}
.eventbox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin: 50px 40px 0;
}
.opportunity {
  background-color: #3b0f10;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-bottom: 100px;
}
.opportunity img {
  position: absolute;
  left: 10px;
  width: 375px;
}
.motivation {
  margin: 50px 0;
  width: 800px;
}
#motivationhead {
  text-align: center;
  text-transform: uppercase;
  font-family: AkiraExpanded-SuperBold;
  font-size: 20px;
  color: #fff;
}
#motivationtext {
  font-family: montserrat;
  font-size: small;
  font-weight: 600;
  color: #fff;
}
.headerTabs {
  display: flex;
  gap: 30px;
  align-items: center;
}
.headerTabsMob {
  display: inline-block;
  cursor: pointer;
}
.headerTabsMobContainer {
  display: none;
}
@media only screen and (max-width: 500px) {
  .img_background {
    height: 300px !important;
    width: 300px !important;
    margin-top: 70px !important;
    object-fit: cover;
  }
  .approachsec {
    margin-top: 350px !important;
  }
  .vidcontainer p {
    font-size: 20px;
    color: white;
    width: 80% !important;
    font-family: AkiraExpanded-SuperBold;
    line-break: inherit;
  }
  .vidcontainer video {
    width: 80% !important;
    border-radius: 15px;
    height: 400px;
  }
}
@media only screen and (max-width: 800px) {
  .col1,
  .col2 {
    display: flex;
    flex-direction: column !important;
    clear: none;
    margin-left: 0 !important;
  }
  .fixed {
    align-items: center;
    width: 100%;
    float: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media only screen and (max-width: 1400px) {
  .feedbackcontent {
    margin: 0 0 100px !important;
    padding: 0 !important;
  }
  .feedbacksec,
  .fixed {
    display: flex;
    flex-direction: column;
  }
  .fixed {
    align-items: center;
    width: 100%;
    float: none;
    justify-content: center;
  }
}
@media only screen and (max-width: 1024px) {
  .headerTabsMobContainer {
    display: flex;
    align-items: center;
  }
  .sound {
    display: none;
  }
  .feedbackcontent {
    width: 100% !important;
    margin: 0 0 30px !important;
  }
  .locimg {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 30px 200px;
    gap: 15px;
  }
  .contentbox {
    width: 80% !important;
    right: unset !important;
    top: 200px !important;
  }
  .students_img,
  .tag {
    display: none;
  }
  .blogimg {
    width: 100% !important;
    height: 30vh;
  }
  .blogs {
    align-items: center;
    margin: 50px 0 20px !important;
  }
  .blogs,
  .lochead {
    display: flex;
    flex-direction: column;
  }
  .lochead {
    align-items: start;
    margin-bottom: 10px;
  }
  .highlight {
    width: 100% !important;
    margin-left: 0;
  }
  .eventcontent {
    width: 100%;
    padding: 20px;
  }
  .intro {
    width: 80%;
  }
  .events {
    width: 100% !important;
    height: max-content !important;
    display: flex !important;
    flex-direction: column !important;
  }
  #eventimg {
    object-fit: cover;
    width: 100%;
    height: 250px;
  }
  .img_background {
    height: 400px;
    width: 400px;
    margin-top: 40px;
    object-fit: cover;
  }
  .introbox {
    flex-direction: column-reverse;
    gap: 30px;
  }
  .bio,
  .introbox {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .bio {
    display: flex;
  }
  .profile {
    height: 200px;
    width: 200px;
    object-fit: cover;
  }
  .eventbox {
    gap: 20px;
    flex-direction: column;
  }
  #eventbutton,
  .eventbox {
    display: flex;
    align-items: center;
  }
  #eventbutton {
    width: 85% !important;
    margin-top: 10px;
  }
  .headerTabs {
    display: none;
  }
  .feedbacksec,
  .fixed {
    display: flex;
    flex-direction: column;
  }
  .fixed {
    width: 100%;
    float: none;
    align-items: center;
    justify-content: center;
  }
  .col1 {
    margin-left: 0 !important;
  }
  .col2 {
    display: flex;
    align-items: center;
    justify-content: center !important;
    flex-direction: column !important;
  }
}
@media only screen and (max-width: 1300px) {
  .tag {
    position: absolute;
    width: 220px;
    left: 20px;
    top: 60px;
  }
  .students_img {
    left: 70px;
    top: 100px;
    width: 300px;
  }
  .contentbox {
    width: 30%;
    right: 0;
    top: 50px;
  }
  .content_footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .heroHeading {
    text-align: center;
  }
  .feedbacksec {
    width: 100%;
  }
  .feedbacksec,
  .locimg {
    display: flex;
    flex-direction: column;
  }
  .locimg {
    flex-wrap: wrap;
    align-items: center;
    margin: 0 30px 200px;
    gap: 15px;
  }
}
.change .bar1 {
  transform: translateY(11px) rotate(-45deg);
}
.change .bar2 {
  opacity: 0;
}
.change .bar3 {
  transform: translateY(-11px) rotate(45deg);
}
.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: #ffc353;
  margin: 6px 0;
  transition: 0.4s;
}
.headerTabGroup {
  display: flex;
  flex-direction: row;
}
.headerTabGroup button.active {
  border-bottom: 1px solid #ffc353;
}
.headerTabText {
  font-family: montserrat;
  color: #fff;
  display: inline-flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background: #3b0f10;
  border: none;
}

.tabYouthText {
  color: #d1f862 !important;
  font-family: "Fira Sans", sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  text-shadow: #b8db56 1px 1px 2px;
}
.headerTabText:hover {
  cursor: pointer;
}
.headerTabText a {
  font-family: montserrat;
  text-decoration: none;
  color: #fafafa;
  align-content: center;
}
.registerbox {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-content: center;
}
.blogsec {
  margin-bottom: 150px;
}
.blog {
  text-align: center;
  text-transform: uppercase;
  font-family: AkiraExpanded-SuperBold;
  font-size: 20px;
}
.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 20px;
}
.blogbutton {
  border-radius: 20px;
  border: none;
}
.blogbutton:hover,
.blogbuttonActive,
.navbar button.active {
  background-color: #ffc353;
}
.blogbutton a {
  text-decoration: none;
}
.blogbutton p {
  color: #0d0d0d;
  font-family: montserrat;
  font-size: small;
  font-weight: 600;
  padding: 0 20px;
}
.blogdisplay {
  display: none;
  flex-direction: column;
  margin-top: 30px;
}
.blogs {
  display: flex;
  width: 700;
  margin: 20px 100px 0;
}
.blogimg {
  width: 500px;
  border-radius: 15px;
}
.blogcontent {
  margin-left: 20px;
  margin-top: 30px;
}
.bloghead1 {
  text-transform: uppercase;
  font-family: AkiraExpanded-SuperBold;
  font-size: 20px;
}
.blogpara1 {
  font-family: montserrat;
  font-size: 16px;
  font-weight: 500;
}
#readmore {
  box-shadow: 2px 2px #d6d6d6;
  text-align: center;
  width: 120px;
}
.feedbacksec {
  margin-bottom: 200px;
  display: flex;
  gap: 30px;
}
.feedbackcontent {
  display: block;
  width: 400px;
  padding: 40px 5px 5px;
  float: left;
  margin: 0 50px 100px 200px;
}
.fbsuccess {
  font-family: AkiraExpanded-SuperBold;
  font-size: 20px;
}
.fbhead,
.fbsuccess {
  text-transform: uppercase;
  margin: 0;
}
.fbhead {
  font-family: teko;
  color: #3b0f10;
  font-size: 40px;
  font-weight: 500;
}
.fbpara {
  font-family: montserrat;
  font-size: small;
  font-weight: 600;
  margin: 0;
}
.fb_box {
  width: 300px;
  background-color: #fff;
  border: 3px solid #f4eddf;
  border-radius: 15px;
  padding: 25px;
  position: relative;
}
.commas {
  z-index: 1;
  position: absolute;
  float: left;
  top: 15px;
}
.fb {
  position: relative;
  z-index: 2;
  font-family: montserrat;
  font-size: small;
  font-weight: 600;
}
.fbname {
  font-weight: 800;
}
.fbloc,
.fbname {
  position: relative;
  z-index: 2;
  font-family: montserrat;
  font-size: small;
  color: #3b0f10;
}
.fbloc {
  font-weight: 700;
}
.fixed {
  float: left;
}
.col1,
.col2 {
  display: flex;
  flex-direction: column;
  clear: left;
  gap: 30px;
}
.col1 {
  margin-left: 350px;
}
.col3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.vidcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #3b0f10;
  padding: 60px 0px 70px 0px;
}

.vidcontainer div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.vidcontainer p {
  font-size: 20px;
  color: white;
  width: 50%;
  font-family: AkiraExpanded-SuperBold;
  line-break: inherit;
}
.vidcontainer video {
  width: 50%;
  border-radius: 15px;
  height: 400px;
}
