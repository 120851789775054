.ach_head {
  font-family: "Fira Sans", sans-serif;

  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.ach_date {
  font-family: "Fira Sans", sans-serif;
  color: #79747e;
  font-size: 16px;
  text-transform: capitalize;
}

.ach_title {
  font-family: "Fira Sans", sans-serif;
  text-transform: capitalize;
  color: black;
  font-weight: 500;
}
